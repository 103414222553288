<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header my-top-khan-title"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.payments_expense")}}</p>
                    <!-- <el-button @click="closeModal()" type="warning" plain>{{$t("message.close")}}</el-button> -->
                    <crm-store-update-close
                    :permission="$options.name"
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="closeModal()"
                ></crm-store-update-close>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 " v-loading="loadStaff">
              <div class="timeline-items__right rounded-sm w-100 p-4 type-expensies" :class="mode ? 'table__myday' : 'table__mynight'">
                <el-form
                ref="form"
                status-icon
                :model="form"
                :rules="rules"
               
                >
                <el-row :gutter="20">
                  <el-col :span="12" >
                    <el-form-item >
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                        <span class="start_top">*</span>
                        {{ $t("message.datas") }}
                      </span>
                        <el-date-picker
                        :class="mode ? 'input__day' : 'input__night'"
                          style="width:100%"
                          :size="'medium'"
                          v-model="form.date"
                          type="datetime"
                          :placeholder="$t('message.datas')">
                        </el-date-picker>
                      </el-form-item> 
                  </el-col>
                  <!-- <el-col :span="12" >
                    <el-form-item prop="type_expense_id">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                        <span class="start_top">*</span>
                        {{ $t("message.type_expense") }}
                      </span>
                      <select-type-expense
                        :size="'medium'"
                        :id="form.type_expense_id"
                        v-model="form.type_expense_id"
                      ></select-type-expense>
                    </el-form-item> 
                  </el-col> -->
                  
                  <el-col :span="12">
                    <el-form-item prop="paid_amount">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                        <span class="start_top">*</span>
                        {{ $t("message.amount") }}
                      </span>
                      <crm-input
                        :class="mode ? 'input__day' : 'input__night'"
                        :inputValue="form.money_amount"
                        :placeholder="$t('message.amount')"
                        v-model="form.money_amount"
                        size="medium"
                        :type="'number'"
                      ></crm-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="currency_id">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                        <span class="start_top">*</span>
                        {{ $t("message.currency") }}
                      </span>
                      <select-currency
                        @c-change="updateCurrency()"
                        :id="form.currency_id"
                        v-model="form.currency_id"
                      ></select-currency>
                    </el-form-item>  
                    <el-form-item v-if="form.currency && !form.currency.is_active" label="Курс" prop="rate" size="small">
                      <el-input v-model="form.currency_rate" type="number"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                    :class="mode ? 'input__day' : 'input__night'"
                        :label="$t('message.whose_side')"
                        prop="whose_side"
                        class="label_mini"
                    >
                      <el-select
                        class="w-100"
                        v-model="form.whose_side"
                        :class="mode ? 'input__day' : 'input__night'"
                        size="small"
                      >
                        <el-option label="Xodim Tomonidan" value="by_employe"></el-option>
                        <el-option label="Kompaniya tomonidan" value="by_company"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="form.whose_side=='by_employe'">
                    <el-form-item prop="staff_ids">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                        <span class="start_top">*</span>
                        {{ $t("message.staff_ids") }}
                      </span>
                      <!-- {{ staff_list }} -->
                      <el-select
                          :placeholder="$t('message.staff')"
                          :multiple="true"
                          v-model="form.staff_ids"
                          class="w-100"
                          :class="mode ? 'input__day' : 'input__night'"
                          size="small"
                        >
                        <el-option
                            v-for="(staff, index) in staff_list"
                            :key="'staff_ids' + index"
                            :label="staff.name+' '+staff.last_name"
                            :value="staff.id"
                          >
                      </el-option>
                        </el-select>
                    </el-form-item>  
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="payment_type">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                        <span class="start_top">*</span>
                        {{ $t("message.payment_type") }}
                      </span>
                      <el-select
                        class="w-100"
                        v-model="form.payment_type"
                        size="small"
                        :placeholder="$t('message.payment_type')"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                        <el-option
                          v-for="(type, index) in payment_types"
                          :key="'payment_types' + index"
                          :label="type.label"
                          :value="type.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item> 
                  </el-col>
                  
                  <el-col :span="12">
                    <el-form-item prop="account_id">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                        <span class="start_top">*</span>
                        {{ $t("message.check") }}
                      </span>
                      <select-account
                        :currency_id="form.currency_id"
                        :id="form.account_id"
                        v-model="form.account_id"
                      ></select-account>
                    </el-form-item>  
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="branch_id">
                      <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                        <span class="start_top">*</span>
                        {{ $t("message.branch") }}
                      </span>
                      <select-branch
                        :id="form.branch_id"
                        v-model="form.branch_id"
                      ></select-branch>
                    </el-form-item>  
                  </el-col>
                 
                    <el-col :span="12" >
                      <el-form-item
                        prop="comment"
                        class="label_mini">
                        <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-0 line-h-24">
                          {{ $t("message.comment") }}
                        </span>
                        <el-input
                            type="textarea"
                            :rows="1"
                            :placeholder="$t('message.comment')"
                            :inputValue="form.comment"
                            v-model="form.comment"
                            class="text-area-el"
                            :class="mode ? 'input__day' : 'input__night'"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import closeDialog from "../../../utils/mixins/dialog"
import drawer from "@/utils/mixins/drawer";
import moment from "moment";
import form from "@/utils/mixins/form";
export default {
    name: "create-expense",
    mixins: [form, drawer],
    components: {},
    props: {
      business_trip_id: {
        default: null,
      },
      child:{
        default: null,
      },
    },
    data() {
        return {
          // child: {},
          payment_types: [
            { value: "card", label: "Пластик" },
            { value: "cash", label: "Наличный" },
          ],
          loadStaff: false,
            baseUrl: process.env.VUE_APP_URL.substring(
              0,
              process.env.VUE_APP_URL.length - 3
            ),
        };
    },
    computed: {
    ...mapGetters({
        staff_list: "businessTrip/staff_list",
        currencies: 'currency/inventory',
        rules: "paymentsExpense/rules",
        mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
        getStaffList: "businessTrip/getStaffList",
        save: "businessTrip/expenseStore",        
    }),
    submit(close = true) {
          this.form.currency_rate = this.form.currency ? this.form.currency.is_reverse ? _.round(1 / +this.form.currency_rate, 20) : +this.form.currency_rate : +this.form.currency_rate;
          this.form.date = moment(this.form.date).format("YYYY-MM-DD hh:mm:ss");
          this.form.type_expense_id = 1;
          this.form.business_trip_id = this.child.business_trip_id;
          console.log(this.form);
          this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            // this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.closeModal();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    pushToProfile(staff) {
      this.$router.push({
        name: "profile",
        params: { id: staff.id },
      });
    },
    opened(child) {
      if (!this.loadStaff) {
        this.loadStaff = true;
        this.getStaffList(this.child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }

    },
    closeModal() {
      this.$emit("closeCreateExpenseModal", false);
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
    updateCurrency() {
          console.log('salom');
            const currency = _.find(this.currencies, { 'id': this.form.currency_id });
            if (currency) {
                this.form.currency = currency;
                console.log(currency);
                this.form.currency_rate = currency.reverse ? currency.reversed_rate : currency.rate;
            }
        },
  }
};
</script>
<style lang="scss" >
.timeline-items__right {
    margin-bottom: 20px;

    .info-user-left2 {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .img {
            margin-right: 10px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
    }
    .name_u {
        font-weight: bold;
        font-size: 15px;
    }
    .position_u {
        color: #8a8a8a;
        font-size: 12px;
        font-weight: 300;
        margin-top: 2px;
    }
    .salary {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
    }
}
.info-user-left2{
    display: flex;
    align-items: center;
    //background-color: #fff;
    background-clip: border-box;
  //  border: 1px solid rgba(34,41,47,.1254901961);
    border-radius: 0.428rem;
    // box-shadow: 0 4px 24px 0 rgb(34 41 47 / 1%);
    width: 100%;
    cursor: pointer;
    padding: 10px;
}
.my-top-khan-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
     flex-wrap: nowrap;
}
.info-user-left2{
  flex-wrap: nowrap !important;
}
.elemet-content{
  padding: 0 15px;
  height: 100%;
  overflow: auto;
}
.app-modal__box{
  min-height: 100vh;
      background-color: #eef2f4;
}
.el-drawer__body{
  overflow: scroll;
}
</style>
