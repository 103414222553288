<template>
  <div class="p-pegas">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
          <div class="card-table-body ifon-add-title-block">
              <el-row :gutter="20">
                  <el-col :span="12">
                      <div class="caz-blocks-sarcho-title">
                          <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                              {{ $t("message.business_trips") }}
                          </div>
                          <div class="block-sarche">
                              <div class="header__search">
                                  <crm-input
                                      :size="'small'"
                                      :className="'w100'"
                                      :class="mode ? 'input__day' : 'input__night'"
                                      v-model="filterForm.search"
                                      :icon="'el-icon-search'"
                                  ></crm-input>
                              </div>
                          </div>
                      </div>
                  </el-col>

                  <el-col :span="12" class="flex-style text-right">
                      
                      <!-- <crm-create-and-column-settings
                          @c-create="drawerCreate = true"
                          :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                          :columns="columns"
                          :can_create="true"
                          @c-change="updateColumn"
                      >
                      </crm-create-and-column-settings> -->
                      <crm-create-and-column-settings
                        :permission="$options.name"
                        class="mobile__margintop"
                        :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                        @c-create="drawerCreate = true"
                        :columns="columns"
                        @c-change="updateColumn"
                        :v_can_create="'CandidateController@store'"
                        >
                      </crm-create-and-column-settings>
                  </el-col>
              </el-row>
          </div>
          <!-- end ifon-add-title-block -->

          <div class="card-table-header table-crm-smart">
              <table
                  class="table-my-code table-bordered"
                  :class="mode ? 'table__myday' : 'table__mynight'"
                  v-loading="loadingData"
              >
                  <thead>
                      <tr>
                          <th class="w50p" v-if="columns.id.show">
                              {{ columns.id.title }}
                          </th>
                          <th v-if="columns.manager.show">
                              {{ columns.manager.title }}
                          </th>
                          <th v-if="columns.organization.show">
                          {{ columns.organization.title }}
                          </th>
                          <th v-if="columns.purpose.show">
                              {{ columns.purpose.title }}
                          </th>
                          <th v-if="columns.tasks.show">
                            {{ columns.tasks.title }}
                          </th>
                          <th v-if="columns.start_date_time.show">
                              {{ columns.start_date_time.title }}
                          </th>
                          <th v-if="columns.end_date_time.show">
                            {{ columns.end_date_time.title }}
                          </th>
                          <th v-if="columns.staffs.show">
                            {{ $t("message.payments_expense") }}
                          </th>
                          <th v-if="columns.staffs.show">
                            {{ columns.staffs.title }}
                          </th>
                          <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                          </th>
                          <th v-if="columns.updated_at.show">
                              {{ columns.updated_at.title }}
                          </th>

                          <th v-if="columns.settings.show">
                              {{ columns.settings.title }}
                          </th>
                      </tr>

                      <tr class="filter_sorche">
                          <th v-if="columns.id.show">
                              <el-input
                                  clearable
                                  size="mini"
                                  v-model="filterForm.id"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  :placeholder="columns.id.title"
                                  class="id_input"
                              ></el-input>
                          </th>
                          <th v-if="columns.manager.show">
                            <select-staff
                                :id="filterForm.manager_id"
                                :size="'mini'"
                                :placeholder="columns.manager.title"
                                v-model="filterForm.manager_id"
                              ></select-staff>
                          </th>
                          <th v-if="columns.organization.show">
                            <select-organization
                                :id="filterForm.organization_id"
                                :size="'mini'"
                                :placeholder="columns.organization.title"
                                v-model="filterForm.organization_id"
                                className="w-100"
                              >
                            </select-organization>
                          </th>
                          <th v-if="columns.purpose.show">
                              <crm-input
                                  :placeholder="columns.purpose.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.purpose"
                              ></crm-input>
                          </th>
                          <th v-if="columns.tasks.show">
                            <crm-input
                                :placeholder="columns.tasks.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.tasks"
                            ></crm-input>
                          </th>
                          <th v-if="columns.start_date_time.show">
                            <crm-date-picker
                                :placeholder="columns.start_date_time.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.start_date_time"
                            ></crm-date-picker>
                          </th>
                          <th v-if="columns.end_date_time.show">
                            <crm-date-picker
                                  :placeholder="columns.end_date_time.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.end_date_time"
                              ></crm-date-picker>
                          </th>
                          <th v-if="columns.staffs.show">
                           
                          </th>
                          <th v-if="columns.staffs.show">
                           
                          </th>
                          <th v-if="columns.created_at.show">
                              <crm-date-picker
                                  :placeholder="columns.created_at.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.created_at"
                              ></crm-date-picker>
                          </th>

                          <th v-if="columns.updated_at.show">
                              <crm-date-picker
                                  :placeholder="columns.updated_at.title"
                                  :class="mode ? 'filter__day' : 'filter__night'"
                                  v-model="filterForm.updated_at"
                              ></crm-date-picker>
                          </th>
                          <th
                              class="settinW"
                              v-if="columns.settings.show"
                          ></th>
                      </tr>
                  </thead>

                  <transition-group name="flip-list" tag="tbody">
                      <tr
                          v-for="businessTrip in list"
                          :key="businessTrip.id"
                          class="cursor-pointer"
                      >
                          <td v-if="columns.id.show">{{ businessTrip.id }}</td>
                          <td v-if="columns.manager.show">
                            {{ businessTrip.manager.name }} {{ businessTrip.manager.last_name }}
                          </td>
                          <td v-if="columns.organization.show">
                            {{ businessTrip.organization.name }}
                          </td>
                          <td v-if="columns.purpose.show">
                              {{ businessTrip.purpose }}
                          </td>
                          <td v-if="columns.tasks.show">
                            {{ businessTrip.tasks }}
                          </td>
                          <td v-if="columns.start_date_time.show">
                            {{ businessTrip.start_date }}  {{ businessTrip.start_time }}
                          </td>
                          <td v-if="columns.end_date_time.show">
                            {{ businessTrip.end_date }} {{ businessTrip.end_time }}
                          </td>
                          <td v-if="columns.staffs.show">
                            <el-button @click="showExpenseList(businessTrip.id)"  type="success" size="medium"  class="remove-button"><i class="fa-regular fa-eye"></i></el-button>
                          </td>
                          <td v-if="columns.staffs.show">
                            <el-button @click="showStaffList(businessTrip.id)"  type="success" size="medium"  class="remove-button"><i class="fa-regular fa-eye"></i></el-button>
                          </td>
                          <td v-if="columns.created_at.show">
                              {{ businessTrip.created_at }}
                          </td>

                          <td v-if="columns.updated_at.show">
                              {{ businessTrip.updated_at }}
                          </td>

                          <td
                              v-if="columns.settings.show"
                              class="settings-td"
                          >
                              <crm-settings
                                  :name="$options.name"
                                  :model="businessTrip"
                                  :actions="actions"
                                  :permissionShow="'BusinessTripController@update'"
                                  :permissionDestroy="'BusinessTripController@destroy'"
                                  @edit="edit"
                                  @delete="destroy"
                              ></crm-settings>
                          </td>
                      </tr>
                  </transition-group>
              </table>
              <div class="my___pagination">
                  <crm-pagination
                      @c-change="updatePagination"
                      :class="mode ? 'pagination__day' : 'pagination__night'"
                      :pagination="pagination"
                  ></crm-pagination>
              </div>
          </div>

          <div class="app-modal app-modal__full">
              <el-drawer
                  :with-header="false"
                  :wrapperClosable="false"
                  :visible.sync="drawerCreate"
                  size="40%"
                  ref="drawerCreate"
                  @opened="drawerOpened('drawerCreateChild')"
                  @closed="drawerClosed('drawerCreateChild')"
              >
                  <crm-create
                      ref="drawerCreateChild"
                      drawer="drawerCreate"
                  ></crm-create>
              </el-drawer>
              <el-drawer
                  :with-header="false"
                  :wrapperClosable="false"
                  :visible.sync="drawerUpdate"
                  size="50%"
                  ref="drawerUpdate"
                  @opened="drawerOpened('drawerUpdateChild')"
                  @closed="drawerClosed('drawerUpdateChild')"
              >
                  <crm-update
                      :selectedItem="selectedItem"
                      ref="drawerUpdateChild"
                      drawer="drawerUpdate"
                  ></crm-update>
              </el-drawer>
              
              <el-drawer
                title="I'm outer Drawer"
                :visible.sync="showUseerModal"
                size="30%"
                :with-header="false"
                ref="show-users"
                @opened="drawerOpened('show-users')"
              >
                <div>
                  <show-users
                    :staff="selected"
                    @closeUserModal="closeUserModal"
                    :child="child"
                    ref="show-users"
                  ></show-users>
                </div>
              </el-drawer>
              <el-drawer
                title="I'm outer Drawer"
                :visible.sync="showExpenseModal"
                size="70%"
                :with-header="false"
                ref="show-expense"
                :append-to-body="true"
                @opened="drawerOpened('show-expense')"
              >
                <div>
                  <show-expense
                    :staff="selected"
                    @closeExpenseModal="closeExpenseModal"
                    :child="child"
                    ref="show-expense"
                  ></show-expense>
                </div>
              </el-drawer>
          </div>
         
      </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import showUsers from "./components/show-users";
import showExpense from "./components/show-expense";

import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "CompanyController",
  mixins: [list],
  components: {
      CrmCreate,
      CrmUpdate,
      showUsers,
      showExpense
  },

  data() {
    return {
      child: {},
      showUseerModal: false,
      showExpenseModal: false,
      selected: null,
    };
  },
  computed: {
      ...mapGetters({
          list: "businessTrip/list",
          columns: "businessTrip/columns",
          pagination: "businessTrip/pagination",            
          filter: "businessTrip/filter",
          sort: "businessTrip/sort",
          mode: "MODE"
      }),
      actions: function () {
          return ["edit", "delete"];
      },
  },
  methods: {
      ...mapActions({
          updateList: "businessTrip/index",
          setPagination: "businessTrip/setPagination",
          updateSort: "businessTrip/updateSort",
          updateFilter: "businessTrip/updateFilter",
          updateColumn: "businessTrip/updateColumn",
          updatePagination: "businessTrip/updatePagination",
          show: "businessTrip/show",
          empty: "businessTrip/empty",
          delete: "businessTrip/destroy",
          refreshData: "businessTrip/refreshData",
      }),

    showStaffList(businessTripId) {
      this.showUseerModal = true;
      this.child.business_trip_id = businessTripId;
    },
    showExpenseList(businessTripId) {
      this.showExpenseModal = true;
      this.child.business_trip_id = businessTripId;
    },
    closeUserModal(val) {
      this.showUseerModal = val;
    },
    closeExpenseModal(val) {
      this.showExpenseModal = val;
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },
  },
};
</script>
